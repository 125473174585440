import styled from 'styled-components';
import { media } from '../../utils/styled-utils';

export default styled.div`
    position: relative;

    .wrapper {
        margin: 0 auto;
    }

    .mb-144 {
      margin-bottom: 116px;
    }

    .bg-gray {
      background-color: #f9f9fb;
    }

    .brd {
      position: relative;
      &::after {
        position: absolute;
        height: 16%;
        width: 100%;
        left: 0;
        bottom: -1px;
        content: '';
        background-image: linear-gradient(to bottom right, #f9f9fb 50%, #fff 50%);
      }
    }

    .grid {
      max-width: 1232px;
    }

    @media only screen and (max-width: 767px) {
        .wrapper {
            display: none;
        }

        .mb-144 {
            margin-bottom: 16px;
        }
    }
`;

export const LastUpdated = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
  letter-spacing: 0.2px;
  padding-bottom: 100px;
  padding-left: 6px;
  color: ${p => p.theme.color.charcoalGrey};
  
  ${media.less1024} {
    padding-left: 0;
    font-size: 12px;
  }  
`;

export const Title = styled.h1`
  color: ${p => p.theme.color.charcoalGrey};
  font-size: 72px;
  letter-spacing: 1.1px;
  padding: 128px 0 10px;
  font-weight: 600;

  ${media.less1024} {
    font-size: 32px;
    padding: 64px 0 0;
  }
`;

export const Header = styled.h2`
  color: ${p => p.theme.color.charcoalGrey};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  padding-bottom: 60px;

  ${media.less1024} {
    font-size: 16px;
    padding: 16px 0;
  }
`;

export const Header2 = styled.h2`
  color: ${p => p.theme.color.charcoalGrey};
  line-height: 1.33;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.4px;
  padding-bottom: 48px;
  margin-bottom: 0;

  ${media.less1024} {
    font-size: 16px;
    padding: 16px 0;
  }
`;

export const Mask = styled.div`
    text-align: center;
    padding: 90px 0;

    img {
        height: 80px;
    }

  ${media.less1024} {
        img {
            height: 40px;
        }
    }
`;

export const Header3 = styled.h2`
  color: ${p => p.theme.color.dark};
  line-height: 1.33;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.2px;
  padding-bottom: 16px;
  margin-bottom: 0;

  ${media.less1024} {
    font-size: 16px;
    padding: 16px 0;
  }
`;

export const Content = styled.section`
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.2px;
  color: ${p => p.theme.color.slateGrey};
  overflow: auto;
  font-weight: 400;

  .mb-32 {
    margin-bottom: 36px;
  }

  .no-space-list {
    li {
      margin-bottom: 0;
    }
  }

  .pt-12 {
    padding-top: 12px;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  th {
    white-space: nowrap;
  }

  .left,
  .right {
    flex-basis: 45%;
    flex-grow: 0;
  }

  li {
    padding-left: 10px;
    margin-left: 16px;
    margin-bottom: 4px;
  }

  table li {
    margin-left: 0
  }

  mark {
    color: ${p => p.theme.color.dark};
    padding: 4px 8px;
    letter-spacing: 0.1px;
    font-size: 14px;
    border-radius: 2px;
    background-color: #f0f1f5;
    font-family: "Anonymous Pro", "Menlo", "Consolas", "Bitstream Vera Sans Mono", "Courier New", monospace;
  }

  td, th {
    padding: 16px 0;
    vertical-align: top;
    padding-left: 8px;
  }

  td {
    font-weight: 400;
    padding-right: 8px;
    border-bottom: 1px solid ${({ theme }) => (theme.color.silver)}
  }

  td:first-child {
    width: 215px;
    padding-left: 0;
  }

  th:first-child {
    padding-left: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  ${media.less1024} {
    font-size: 16px;

    table {
      font-size: 16px;
      display: flex;
    }

    .flex-container {
        flex-wrap: wrap;
    }

    .left,
    .right {
      flex-basis: 100%;
      flex-grow: 0;
    }

    td:first-child {
      width: auto;
    }
  }
  
  ${media.mobile} {
    table {
      display: block;
    }
    
    table {
      tr {
        display: flex;
        flex-wrap: wrap;
      }
      
      td {
        flex: 100%;
      }
      
      td:first-child {
        padding-bottom: 8px;
      }
      
      td:last-child {
        border-bottom: none;
        padding-top: 8px;
        padding-left: 0;
      }
      
      tr:last-child {
        td:first-child {
          border-bottom: 1px solid hsla(0, 0%, 0%, .12);
        }
      }
    }
  }
`;

export const Article = styled.article`
  padding-bottom: 72px;
  
  &:last-child {
    padding-bottom: 0;
  }

  @media (max-width: 767px) {
    padding: 16px 0;
  }
`;
