import * as React from 'react';
import { withNamespaces } from 'react-i18next';

import Page, { Article, Content, Header, Header2, Header3, LastUpdated, Title } from './PrivacyPolicyBody.styled';
import Grid from '../Grid';
import Cell from '../Cell';
import Api from '../../utils/api';
import NotAMemberComponent from '../NotAMemberComponent';
import PageHeaderTitle from '../PageHeaderTitle';

class PrivacyPolicyBody extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  render() {
    const { t, brand } = this.props;

    return (
      <Page>
        <PageHeaderTitle brandName={brand.name} title={t('page_privacy_policy:privacy_policy')} />
        <div className='wrappper bg-gray mb-144 brd'>
          <Grid className='grid'>
            <Cell desktop={12} tablet={8} phone={4}>
              <div>
                <Title>
                  {t('page_privacy_policy:h1_title')}
                </Title>
                <LastUpdated className='last-updated'>
                  {t('page_privacy_policy:last_updated')}
                </LastUpdated>
              </div>
            </Cell>
          </Grid>
        </div>
        <div className='wrappper'>
          <Grid className='grid'>
            <Cell desktop={12} tablet={8} phone={4}>
              <section>
                <Header>
                  {t('page_privacy_policy:subtitle')}
                </Header>
              </section>
              <section>
                <Article>
                  <Header2>
                    {t('page_privacy_policy:title_2')}
                  </Header2>
                  <Header3>
                    {t('page_privacy_policy:subtitle_2')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_1')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2_1') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_2')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2_2') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_3')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2_3') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_4')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2_4') }} />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_1')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_1')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_1_1') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_2')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_1_2') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_3')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_1_3') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_4')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_1_4') }} />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_2a')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_1')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2a_1') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_2')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2a_2') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_3')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2a_3') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_4')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_2a_4') }} />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_3')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_1')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_3_1') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_2')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_3_2') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_3')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_3_3') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_4')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_3_4') }} />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_4')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_1')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_4_1') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_2')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_4_2') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_3')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_4_3') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_4')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_4_4') }} />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_5')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_1')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_5_1') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_2')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_5_2') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_3')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_5_3') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_4')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_5_4') }} />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_6')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_1')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_6_1') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_2')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_6_2') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_3')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_6_3') }} />
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_4')}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:table_value_6_4') }} />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header2>
                    {t('page_privacy_policy:title_1')}
                  </Header2>
                  <Content>
                    {t('page_privacy_policy:text_1')}
                    <div className='no-space-list' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:list_1') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_7')}
                  </Header3>
                  <Content>
                    <div className='pt-12' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_7') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_8')}
                  </Header3>
                  <Content>
                    <div className='pt-12' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_8') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_11')}
                  </Header3>
                  <Content>
                    <div className='mb-32 pt-12'>
                      {t('page_privacy_policy:text_11_1')}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_11_2') }} />
                    <div dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_11_3') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_9')}
                  </Header3>
                  <Content>
                    <div
                      className='pt-12'
                      dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_9') }}
                    />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_10')}
                  </Header3>
                  <Content>
                    <div
                      className='pt-12'
                      dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_10') }}
                    />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_12')}
                  </Header3>
                  <Content>
                    <table>
                      <tbody>
                        <tr>
                          <th />
                          <th style={{ fontWeight: 600 }}>
                            {t('page_privacy_policy:table_key_5')}
                          </th>
                          <th style={{ fontWeight: 600 }}>
                            {t('page_privacy_policy:table_pay_value_row_0_cell_2')}
                          </th>
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_6')}
                          </td>
                          <td>
                            {t('page_privacy_policy:table_pay_value_row_1_cell_1')}
                          </td>
                          <td>
                            {t('page_privacy_policy:table_pay_value_row_1_cell_2')}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {t('page_privacy_policy:table_key_7')}
                          </td>
                          <td>
                            {t('page_privacy_policy:table_pay_value_row_2_cell_1')}
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_13')}
                  </Header3>
                  <Content>
                    <div className='pt-12' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_13') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_14')}
                  </Header3>
                  <Content>
                    <div
                      className='pt-12'
                      dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_14') }}
                    />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_15')}
                  </Header3>
                  <Content>
                    <div className='pt-12' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_15') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_16')}
                  </Header3>
                  <Content>
                    <div className='pt-12' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_16') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_17')}
                  </Header3>
                  <Content>
                    <div className='pt-12' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_17') }} />
                  </Content>
                </Article>
                <Article>
                  <Header3>
                    {t('page_privacy_policy:subtitle_18')}
                  </Header3>
                  <Content>
                    <div className='pt-12' dangerouslySetInnerHTML={{ __html: t('page_privacy_policy:text_18') }} />
                  </Content>
                </Article>
              </section>
            </Cell>
          </Grid>
          <NotAMemberComponent
            brand={brand.name}
            text={t('page_privacy_policy:not_a_member')}
            buttonText={t('page_privacy_policy:get_anonine')}
            orderUrl={this.api.orderUrl()}
          />
        </div>
      </Page>
    );
  }
}

export default withNamespaces()(PrivacyPolicyBody);
