import React from 'react';
import classnames from 'classnames';

const ROOT = 'mdc-layout-grid__cell';
const isDefined = data => typeof data !== 'undefined';

const Cell = ({
  align,
  className,
  col,
  order,
  phone,
  desktop,
  tablet,
  ...otherProps
}) => (
  <div
    className={classnames(ROOT, {
      [`${ROOT}--span-${col}`]: isDefined(col),
      [`${ROOT}--span-${col}`]: isDefined(col),
      [`${ROOT}--span-${desktop}-desktop`]: isDefined(desktop),
      [`${ROOT}--span-${tablet}-tablet`]: isDefined(tablet),
      [`${ROOT}--span-${phone}-phone`]: isDefined(phone),
      [`${ROOT}--order-${order}`]: isDefined(order),
      [`${ROOT}--align-${align}`]: isDefined(align),
    }, className)}
    {...otherProps}
  />
);

export default Cell;
