import React from 'react';

const Grid = ({ className, children, ...otherProps }) => (
  <div
    className={`${className} mdc-layout-grid`}
    {...otherProps}
  >
    <div className='mdc-layout-grid__inner'>
      {children}
    </div>
  </div>
);

export default Grid;
